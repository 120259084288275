<template>
  <div>
    <div class="wrap">
      <div class="title">
        User Guide
      </div>
      <div class="user-guide-content">
        <div class="container-guide">
          <div class="guide-image">
            <div class="guide-title">
              <div class="small-title">
                Winex User Guide
              </div>
              <div class="main-title">
                파인 와인 NFT 거래소 <b>와넥스 이용 가이드</b>
              </div>
            </div>
            <div class="content">
              <div class="content-pc">
                <div class="image">
                  <img src="~@/assets/images/guide/guide-step-img.png"/>
                </div>
              </div>
              <div class="content-tablet">
                <div class="image">
                  <img src="~@/assets/images/guide/guide-step-tablet.png"/>
                </div>
              </div>
              <div class="content-mobile">
                <div class="image">
                  <img src="~@/assets/images/guide/guide-step-mobile.png"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-guide-wrap">
          <div class="user-guide">
            <p class="user-guide-title">PC 이용가이드</p>
            <div class="contents">
              <GuideListItem v-for="item in pcList" :no="item.no" :title="item.title" :category="item.category" v-bind:key="item.title" :is_mobile="false"></GuideListItem>
            </div>
          </div>

          <div class="user-guide">
            <p class="user-guide-title">모바일 이용 가이드</p>
            <div class="contents">
              <GuideListItem v-for="item in mobileList" :no="item.no" :title="item.title" :category="item.category" v-bind:key="item.title" :is_mobile="true"></GuideListItem>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</template>
<script>
import GuideListItem from "@/components/list/GuideListItem";
export default {
  name:'Guide',
  components: {GuideListItem},
  created() {

  },
  data () {
    return  {
      pcList: [
        {
          no:1,
          category: 'Step 1',
          title:"메타마스크 생성 및 세팅"
        },
/*        {
          no:2,
          category: 'Step 2-1',
          title:"폴리곤 메인넷 세팅 방법"
        },
        {
          no:3,
          category: 'Step 2-2',
          title:"폴리곤 기반 USDC 추가하기"
        },*/
        {
          no:4,
          category: 'Step 2',
          title:"체인질리를 이용한 USDC구매하기"
        },
        {
          no:5,
          category: 'Step 3',
          title:"가스비로 사용할 MATIC 교환 및 가스비 설정하기"
        }
      ],
      mobileList: [
        {
          no:1,
          category: 'Step 1',
          title:"메타마스크 모바일 설치"
        },
        {
          no:2,
          category: 'Step 2',
          title:"와넥스 모바일 이용"
        },
      ]
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/faq.scss";
@import "@/assets/scss/guide.scss";
</style>
