<template>
    <div class="accordion-wrap" @click="click">
        <div class="accordion-title-wrap">
            <span class="category">{{category}}</span>
            <span class="title">{{title}}</span>
        </div>
    </div>
</template>

<script>
    // import {VueSlideToggle} from 'vue-slide-toggle'
    export default {
        name: "GuideListItem",
        props: {
            no: {
                type: Number
            },
            category: {
                type: String
            },
            title: {
                type:String
            },
            is_mobile: {
              type: Boolean
            }
        },
        components: {

        },
        data() {
            return {
                openAccordion:false
            }
        },
        mounted() {

        },
      methods: {
            click() {
              if(this.is_mobile){
                this.$router.push(
                    {
                      path : '/cs/guide/mobile/detail/' + this.no
                    }
                );
              }else{
                this.$router.push(
                    {
                      path : '/cs/guide/detail/' + this.no
                    }
                );
              }

              //this.$router.query.push(this.title);
            }
        }
    }
</script>

<style lang="scss" scoped>
  .content-wrap {
    display: flex;
    @media (max-width: 768px){
      width: 100% !important;
      flex-direction: column;
    }
    p {
      width: 100%;
      font-size: 16px;
      text-align: left;
      //line-height: 28px;
      //border-bottom: 1px solid #ccc;
      //padding-bottom: 10px;
      //margin-bottom: 10px;
    }
    img {
      width: 100%;
      margin-bottom:10px;
      @media (max-width: 768px){
        display: inline-block;
        width: 200px;
      }
    }
  }
</style>
